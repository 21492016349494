import * as React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import {  useNavigate } from 'react-router-dom';
import { logoutUser } from '../../redux/actions/userAction';

const mapStateToProps = state => {
    return {
        user: state.user
    }
}
const mapDispatchToProps = dispatch => {
    return {
        logoutUser: () => dispatch(logoutUser())
    }
}

function Logout({user, logoutUser}) {

    const navigate = useNavigate()

    useEffect( () => {
        logoutUser();
        window.location.href="/";
    }, []);
  return (
    <>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
