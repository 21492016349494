import { combineReducers } from 'redux'
import { ERROR, ERRORS, ERRORS_REMOVE, ERROR_REMOVE, REMOVE_LOADING, REMOVE_LOADINGS, SET_LOADING, SET_LOADINGS, MESSAGE, MESSAGE_REMOVE, MODAL_ENABLE, MODAL_DISABLE, TAB, TAB_REMOVE, MESSAGES, MESSAGES_REMOVE } from './types'
import { userReducer } from './reducers/userReducer'
import { errorReducer } from './reducers/errorReducer'
import { errorsReducer } from './reducers/errorReducers'
import { loadingsReducer } from './reducers/loadingsReducer'
import { loadingReducer } from './reducers/loadingReducer'
import { messageReducer } from './reducers/messageReducer'
import { modalReducer } from './reducers/modalReducer'
import { tabReducer } from './reducers/tabReducer'

export const rootReducer = combineReducers({
  session: userReducer,
  modal: modalReducer,
  tab: tabReducer,
  message: messageReducer,
  error: errorReducer,
  errors: errorsReducer,
  loading: loadingReducer,
  loadings: loadingsReducer
})

export const showModal = (data, content = null) =>{
  return {
    type: MODAL_ENABLE,
    payload: { data },
    content: { content }
  }
}

export const hideModal = (data) =>{
  return{
    type: MODAL_DISABLE,
    payload: { data }
  }
}

export const setTab = (data) =>{
  return{
    type: TAB,
    payload: { data }
  }
}

export const removeTab = () =>{
  return{
    type: TAB_REMOVE
  }
}
export const setMessages = (text, type) =>{
  return{
    type: MESSAGES,
    payload: { text, type }
  }
}
export const removeMessages = (text) =>{
  return{
    type: MESSAGES_REMOVE,
    payload: { text }
  }
}

export const setMessage = (message, type) =>{
  return{
    type: MESSAGE,
    payload: { message, type }
  }
}
export const removeMessage = () =>{
  return{
    type: MESSAGE_REMOVE
  }
}

export const setError = (data) =>{
  return{
    type: ERROR,
    payload: { data }
  }
}

export const setErrors = (data) =>{
  return{
    type: ERRORS,
    payload: { data }
  }
}

export const removeError = (data) =>{
  return{
    type: ERROR_REMOVE,
    payload: { data }
  }
}

export const removeErrors = () =>{
  return{
    type: ERRORS_REMOVE,
  }
}

export const setLoading = (data) =>{
  return {
    type: SET_LOADING,
    payload: { data }
  }
}

export const removeLoading = (data) =>{
  return {
    type: REMOVE_LOADING,
    payload: { data }
  }
}

export const setLoadings = (data) =>{
  return {
    type: SET_LOADINGS,
    payload: { data }
  }
}

export const removeLoadings = (data) =>{
  return {
    type: REMOVE_LOADINGS,
    payload: { data }
  }
}