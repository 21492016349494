import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { Persistor, store } from './redux/store';
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from 'redux-persist/integration/react';
import { createTheme, ThemeProvider } from '@mui/material';
import Router from './router/Router';
import SnackbarUI from './components/UI/SnackbarUI';
import './translate';
import './assets/styles/App.scss';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import Modals from './components/Modals/Modals';

const root = ReactDOM.createRoot(document.getElementById('root'));

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  }
});

const lightTheme = createTheme({
  palette: {
    mode: 'light',
  }
});


root.render(
      <Provider store={store}>
        <PersistGate loading={null} persistor={Persistor}>
          <BrowserRouter>
            <ThemeProvider theme={darkTheme}>
                <Router/>
                <SnackbarUI/>
                <Modals/>
            </ThemeProvider>
          </BrowserRouter>
        </PersistGate>
      </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
