import Logout from "../components/Logout/Logout"
import Main from "../pages/Main/Main"
import { Navigate } from 'react-router-dom';

export const pages = [
    {
        path: '/',
        component: <Main />
    },
    {
        path: '/login',
        component: <Main />
    },
    {
        path: '/logout',
        component: <Logout />
    },
    {
        path: '*',
        component: <Navigate to="/"/>
    }
];