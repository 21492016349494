import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { pages } from './pages';

function MainContainer() {
    return (
        <Routes>
            {pages.map((page, i) => {
                return <Route key={'p'+i} path={page.path} element={page.component} />
            })};
        </Routes>
    );
}

export default (MainContainer);
