//frontend
const API = '/api/v1/';

export const HOME_ROUTE = '/';
export const FRONTEND_URL = process.env.REACT_APP_APP_URL;
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const LOGIN_ROUTE = '/login';
export const RESET_ROUTE = '/password/reset';
export const REGISTRATION_ROUTE = '/register';
export const PROFILE_ROUTE = '/account';

// core api
export const CORE_API_LOGIN_ROUTE = '/user/auth/login';

//api
export const API_REGISTRATION_ROUTE = API + 'auth/register';
export const API_LOGIN_ROUTE = API + 'auth/login';
export const API_LOGOUT_ROUTE = API + 'auth/logout';

export const API_USER_FINDME_ROUTE = API + 'findMe';

export const API_FETCH_ENVIRONMENT_ROUTE = API + 'environment';

export const API_FETCH_USER = API + 'user/';
export const API_USER_UPDATE = API + 'user/';

export const API_FETCH_POSTS = API + 'posts';
export const API_POST = API + 'post';
export const API_FETCH_POST = API + 'post/';

export const API_FETCH_COMMENT = API + 'comment/';
export const API_COMMENT_UPDATE = API + 'comment/';
export const API_COMMENT_DELETE = API + 'comment/';

export const API_FETCH_TAGS = API + 'tags';
export const API_TAG_DELETE = API + 'tag/';
export const API_FETCH_TAG_POSTS = API + 'tag/';

export const timeoutTime = 700;