import { useNavigate } from "react-router-dom"
import { API_LOGIN_ROUTE, API_LOGOUT_ROUTE, API_REGISTRATION_ROUTE, API_USER_FINDME_ROUTE, RESET_ROUTE } from "../../utils/consts"
import { setError, setErrors, setMessage } from "../rootReducer"
import { USER, AUTH_LOGIN, AUTH_LOGOUT } from "../types"
import { headers, headers_auth } from "../config";
import axios from 'axios';

const token = sessionStorage.getItem("access_token");

const userLogin = (data) => {
  return {
    type: AUTH_LOGIN,
    payload: { data }
  }
}

const userLogout = (data) => {
  return {
    type: AUTH_LOGOUT,
    payload: { data }
  }
}

const oneUser = (data) => {
  return {
    type: USER,
    payload: { data }
  }
}

export const setNewPasswordAction = (data) => async (dispatch) => {
  try {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + RESET_ROUTE, {
      method: "POST",
      headers: headers_auth,
      body: JSON.stringify(data),
      credentials: 'include',
    })
    
    if(response.status == 200){
      const data = await response.json()
      dispatch(setMessage(data.message, data.type))
    } else {
      const data = await response.json()
      dispatch(setErrors(data.errors))
    }
  
  } catch (error) {
    dispatch(setErrors(error));
    return false;
  }
}

export const getUser = () => async (dispatch) => {
  try {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + API_USER_FINDME_ROUTE, {
      method: "GET",
      headers: headers
    })

    const data = await response.json()
    dispatch(oneUser(data))
  } catch (error) {
    dispatch(setError(error))
  }
}

export const loginUser = (user) => async (dispatch) => {
  try {
    const options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(user)
    }

    const response = await fetch(process.env.REACT_APP_BACKEND_URL + API_LOGIN_ROUTE, options)
    const data = await response.json()


    if (data.access_token) {
      dispatch(userLogin(data))
      sessionStorage.setItem("access_token", data.access_token);
    } else {
      dispatch(setError(data.message))
    }
  } catch (error) {
    setMessage(error, 'error');
  }
}

export const logoutUser = () => async (dispatch) => {
  try {
    const options = {
      method: "POST",
      headers: headers
    }

    const response = await fetch(process.env.REACT_APP_BACKEND_URL + API_LOGOUT_ROUTE, options)
    const data = await response.json()

    if (data) {
      dispatch(userLogout(data.data))
      useNavigate('/');
      sessionStorage.removeItem("access_token");
    } else {
      dispatch(setError(data.message))
    }
  } catch (error) {
    setMessage(error, 'error');
  }
}

export const addNewUser = (item) => async (dispatch) => {
  try {
    const options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(item)
    }

    const response = await fetch(process.env.REACT_APP_BACKEND_URL + API_REGISTRATION_ROUTE, options)
    const data = await response.json()

    if (data.access_token) {
      dispatch(userLogin(data));
      window.sessionStorage.setItem("access_token", data.access_token);
      useNavigate('/');
      window.location.href = '/';
    } else {
      const errors = {
        email: data.errors.email,
        username: data.errors.username,
        password: data.errors.password
      }
      dispatch(setErrors(data.errors))
    }
  } catch (error) {
    setMessage(error, 'error');
  }
}





