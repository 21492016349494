import * as React from 'react';
import { Link } from 'react-router-dom'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import RegistrationModal from '../Modals/RegistrationModal'
import LogoutIcon from '@mui/icons-material/Logout';
import Logo from '../../assets/images/Logo.svg'
import { Button } from '@mui/material';

const settings = [
  {
    // icon: 'DeleteIcon',
    name: 'Logout',
    link: '/logout'
  }
];

const Header = ({ session }) => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const [loginUser, setLoginUser] = React.useState(false)

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleScroll = (to) => {
    const elem = document.getElementById(to);
    if (elem) {
      elem.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" })
    }
  };
  const handleNavigateWarehouse = (to) => {
    window.open(to, '_blank');
  };

  return (
    <AppBar className="header" position="static" enableColorOnDark style={{ background: 'var(--navbar-color-bg)' }}>
      <Container className="container" maxWidth="lg">
        <Toolbar disableGutters>
          <div style={{display:'flex',flexDirection: window.innerWidth<900 &&'column',alignItems:'center'}}>
          <Link to='/'>
            {/* LOGO */}
            <Avatar sx={{ display: { xs: 'flex', md: 'none' }, mr: 0.5, width: '200px', height: 'fit-content' }} variant="square" src={Logo} />
          </Link>

          <Link to='/'>
            {/* LOGO */}
            <Avatar sx={{ display: { xs: 'none', md: 'flex' }, mr: 0.5, width: '260px', height: 'fit-content' }} variant="square" src={Logo} />
          </Link>
          <Link onClick={() => handleNavigateWarehouse('https://warehouse-x.io/')} target="_blank"  style={{paddingLeft:  window.innerWidth>900 &&'40px',paddingTop:  window.innerWidth<900 &&'20px' }}>
              <Typography className="typography bold">Visit Warehouse X</Typography>
            </Link>
          </div>

          <Box sx={{ flexGrow: 1 }} />

          <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: '40px', marginRight: '40px' }}>
            <Link onClick={() => handleScroll('about')} to={'/#about'}>
              <Typography className="typography bold">ABOUT WAREHOUSE X</Typography>
            </Link>
            <Link onClick={() => handleScroll('qa')} to={'/#qa'}>
              <Typography className="typography bold">QUESTIONS & ANSWERS</Typography>
            </Link>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip disableHoverListener>
              <Stack direction="row" spacing={1}>
                <Button onClick={() => handleScroll('signup')} className="btn btn-primary" variant="outlined" style={{ color: '#FFFFFF', border: 'none' }}>
                  SIGN UP
                </Button>
              </Stack>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (

                <Link to={setting.link} key={setting.name}>
                  <MenuItem
                    onClick={handleCloseUserMenu}
                    sx={{ fontSize: '13px', color: 'white', display: 'block', fontWeight: 'bold', maxWidth: '220px', display: 'flex', textTransform: 'uppercase' }}>
                    {setting.name === 'logout' ? <LogoutIcon /> : ''}
                    &emsp;{setting.name}
                  </MenuItem>
                </Link>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Header;
