import React from 'react';
import { FormControl, InputLabel, TextField } from '@mui/material';
export default function TextFieldUI({ type, title, onChange, value, icon, sx, style }) {
    if (!title) {
        return <></>;
    }
    return (
        <FormControl variant="standard">
        <InputLabel htmlFor="input-with-icon-textfield">{title}</InputLabel>
        <TextField
            className="TextFieldUI"
            id="input-with-icon-textfield"
            sx={sx}
            type={type ?? 'text'}
            style={style}
            label={title}
            onChange={onChange}
            value={value}
        />
        </FormControl>
    )
}
