import { AUTH_LOGIN, AUTH_LOGOUT, USER_UPDATE } from '../types'

const initialState = {};

export const userReducer = (state = initialState, action) =>{
  const {type, payload} = action

  switch (type) {

    case AUTH_LOGIN: {
      let { data } = payload
      return data
    }

    case AUTH_LOGOUT: {
      return initialState
    }


    case USER_UPDATE: {
      const { data } = payload
      return {
        user:  data.user,
        access_token: state.access_token
      }
    }
    
    default: {
      return state
    }
  }
}
