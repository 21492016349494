import bg1 from "../../assets/images/background/bg1.jpg";
import bg2 from "../../assets/images/background/bg2.jpg";
import bg3 from "../../assets/images/background/bg3.jpg";
import bgX from "../../assets/images/background/x.png";

import banner1 from "../../assets/images/banners/1.jpg";
import banner2 from "../../assets/images/banners/2.png";
import banner3 from "../../assets/images/banners/3.jpg";
import banner4 from "../../assets/images/banners/4.jpg";
import banner5 from "../../assets/images/banners/5.jpg";
import banner6 from "../../assets/images/banners/6.jpg";

import x from "../../assets/images/x.png";

import logo from "../../assets/images/Logo.svg";

import girls from "../../assets/images/girls.png";

import Turnstile from "react-turnstile";

import {
  Accordion,
  AccordionDetails,
  Alert,
  Button,
  Container,
  styled,
  Typography,
} from "@mui/material";
import React, { useRef } from "react";
import Box from "@mui/material/Box";
import Header from "../../components/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { ReactComponent as TextBackground } from "../../assets/images/svg/2.svg";
import { ReactComponent as People } from "../../assets/images/svg/10.svg";
import { ReactComponent as Plus } from "../../assets/images/svg/1.svg";
import { ReactComponent as XPrizes } from "../../assets/images/svg/xprizes.svg";
import { ReactComponent as Facilities } from "../../assets/images/svg/facilities.svg";
import { ReactComponent as Otherjob } from "../../assets/images/svg/otherjob.svg";
import { ReactComponent as Prostaff } from "../../assets/images/svg/prostaff.svg";
import { ReactComponent as Earnings } from "../../assets/images/svg/earnings.svg";
import splash from "../../assets/images/svg/4.svg";
import diamond from "../../assets/images/diamond.png";
import heading from "../../assets/images/become-an-x-model.png";
import SvakomIcon from "../../assets/images/icons/svg/svacom.svg";
import TextFieldUI from "../../components/UI/TextFieldUI";
import { Link } from "react-router-dom";
import { setMessage } from "../../redux/rootReducer";

import { useInViewport } from "react-in-viewport";
import { Helmet } from "react-helmet";
import Stack from "@mui/material/Stack";

function generateStructuredData() {
  const questions = [
    {
      question: "Who else will be there?",
      answer:
        "Initially there will be non-male models only allowed to live at Warehouse X. There are future plans for a couple’s version of Warehouse X but for now, it’s for the exclusive of the non-male version of human.",
    },
    {
      question: "Will I be safe?",
      answer:
        "The Warehouse studio is staffed 24/7 by managers, supervisors, and security staff. Safety is our top priority, and you will not only feel at home but will be safer during your time as a housemate at Warehouse X.",
    },
    {
      question: "Where is Warehouse X located?",
      answer:
        "The studio itself is located in Tallinn, Estonia. Tallinn is a very vibrant and wealthy city in the European Union and has been dubbed “The Silicone Valley of Europe”. Tallinn has a heritage-listed old town and is located seaside with many award-winning cafés and restaurants.",
    },
    {
      question: "How will I get there?",
      answer:
        "We provide flights from the nearest major city to you and can help with travel from other locations. Sometimes we will recoup the flight costs from your earnings once you earn over a certain amount (a lot of $), and this is usually assessed on a case-by-case basis.",
    },
    {
      question: "Can I leave the Warehouse?",
      answer:
        "Of course, you can leave the warehouse! There is a major shopping mall nearby, as well as the beautiful beaches that Tallinn has in the summertime, as well as tons of restaurants and sites to see in the beautiful city of Tallinn.",
    },
    {
      question: "Can I promote my profile elsewhere while at X?",
      answer:
        "Yes, you are welcome to promote other sites that bring you income, including Only Fans. We also encourage you to cam to your regular webcam sites from our studios while you are staying with us.",
    },
    {
      question: "Content Protection",
      answer:
        "Any content created at Warehouse X is protected by DMCA takedown service, so if anyone should try to repost your content on another site like a tube site, for example, we will work with DMCA to have it removed.",
    },
  ];

  return JSON.stringify({
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: questions.map((item, index) => ({
      "@type": "Question",
      name: item.question,
      acceptedAnswer: {
        "@type": "Answer",
        text: item.answer,
      },
      position: index + 1, // Add position for each question
    })),
  });
}

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<Plus />} {...props} />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "transparent" : "transparent",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

function Main() {
  React.useEffect(() => {
    // Create and append the JSON-LD script tag to the head of the document
    const script = document.createElement("script");
    script.type = "application/ld+json";
    script.innerHTML = generateStructuredData();
    document.head.appendChild(script);

    // Clean up the script tag on component unmount
    return () => {
      document.head.removeChild(script);
    };
  }, []); // Run the effect only once on component mount

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const session = useSelector((state) => state.session);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [name, setname] = React.useState("");
  const [isnta, setinsta] = React.useState("");
  const [email, setemail] = React.useState("");
  const [about, setabout] = React.useState("");
  const [country, setcountry] = React.useState("");

  const [error, setError] = React.useState(null);

  const [render1, set1Render] = React.useState(false);
  const [render2, set2Render] = React.useState(false);
  const [render3, set3Render] = React.useState(false);
  const [render4, set4Render] = React.useState(false);
  const [render5, set5Render] = React.useState(false);
  const [render6, set6Render] = React.useState(false);
  const [render7, set7Render] = React.useState(false);

  const ref1 = React.useRef(null);
  const ref2 = React.useRef(null);

  const isInViewport1 = useIsInViewport(ref1);
  const isInViewport2 = useIsInViewport(ref2);

  const [appeared1, setAppeared1] = React.useState(false);
  const [appeared2, setAppeared2] = React.useState(false);

  const box1 = useRef();
  const box2 = useRef();
  const box3 = useRef();
  const box4 = useRef();
  const box5 = useRef();
  const box6 = useRef();
  const box7 = useRef();

  React.useEffect(() => {
    if (isInViewport1) {
      setAppeared1(true);
    }
    if (isInViewport2) {
      setAppeared2(true);
    }
  }, [isInViewport1, isInViewport2]);

  let options;
  let props;
  let config;

  const { Box1inViewport, enterCount1, leaveCount1 } = useInViewport(
    box1,
    options,
    (config = { disconnectOnLeave: false }),
    props
  );
  const { Box2inViewport, enterCount2, leaveCount2 } = useInViewport(
    box2,
    options,
    (config = { disconnectOnLeave: false }),
    props
  );
  const { Box3inViewport, enterCount3, leaveCount3 } = useInViewport(
    box3,
    options,
    (config = { disconnectOnLeave: false }),
    props
  );
  const { Box4inViewport, enterCount4, leaveCount4 } = useInViewport(
    box4,
    options,
    (config = { disconnectOnLeave: false }),
    props
  );
  const { Box5inViewport, enterCount5, leaveCount5 } = useInViewport(
    box5,
    options,
    (config = { disconnectOnLeave: false }),
    props
  );
  const { Box6inViewport, enterCount6, leaveCount6 } = useInViewport(
    box6,
    options,
    (config = { disconnectOnLeave: false }),
    props
  );
  const { Box7inViewport, enterCount7, leaveCount7 } = useInViewport(
    box7,
    options,
    (config = { disconnectOnLeave: false }),
    props
  );

  React.useEffect(() => {
    if (Box1inViewport) set1Render(Box1inViewport);
    if (Box2inViewport) set2Render(Box2inViewport);
    if (Box3inViewport) set3Render(Box3inViewport);
    if (Box4inViewport) set4Render(Box4inViewport);
    if (Box5inViewport) set5Render(Box5inViewport);
    if (Box6inViewport) set6Render(Box6inViewport);
    if (Box7inViewport) set7Render(Box7inViewport);
  }, [
    Box1inViewport,
    Box2inViewport,
    Box3inViewport,
    Box4inViewport,
    Box5inViewport,
    Box6inViewport,
    Box7inViewport,
  ]);

  const handleScroll = (to) => {
    const elem = document.getElementById(to);
    if (elem) {
      elem.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  };

  const handleSend = async (token) => {
    setOpen(false);
    setError(null);
    if (
      name !== "" &&
      isnta !== "" &&
      email !== "" &&
      country !== "" &&
      token
    ) {
      let data = {
        name: name,
        email: email,
        instagram: isnta,
        about: about,
        country: country,
        "cf-turnstile-response": token,
      };
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + "/api/v1/auth/stars-signup",
        {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        dispatch(setMessage(data.message));
        setname("");
        setemail("");
        setinsta("");
        setabout("");
        setcountry("");
      } else {
        const data = await response.json();
        let error;
        if (data.errors.email) {
          error = data.errors.email;
        }
        if (data.errors.name) {
          error = data.errors.name;
        }
        if (data.errors.instagram) {
          error = data.errors.instagram;
        }
        if (data.errors.about) {
          error = data.errors.about;
        }
        if (data.errors.country) {
          error = data.errors.country;
        }
        if (data.errors[0]) {
          error = data.errors[0];
        }
        setError(error);
      }
    } else {
      dispatch(setMessage(t("Please fill out the form"), "warning"));
    }
  };

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://stars.warehouse-x.io" />
        <title>{t("Become a Webcam Model – Join Warehouse X Now!")}</title>
      </Helmet>
      <Box
        id="head"
        className="background"
        sx={{
          backgroundImage: "url(" + bg1 + ")",
          boxShadow: "inset 0 -50px 650px black",
        }}
      >
        <Container maxWidth="xl">
          <Header session={session} />
          <Box
            className="container flex column content-center"
            sx={{ minHeight: "90vh", position: "relative" }}
          >
            <Box
              className="fx-col-box"
              sx={{
                maxWidth: "795px",
                // marginBottom: {
                //   xs: "none",
                //   sm: "none",
                //   md: "220px",
                //   lg: "220px",
                // },
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  left: { xs: "-5%", sm: "-6%", md: "-47px", lg: "-47px" },
                }}
              >
                <img src={heading} alt={t("Become an X model")} />
              </Box>

              <Typography
                variant="h2"
                className="typography third"
                sx={{
                  mt: 1,
                  marginBottom: {
                    xs: "100px",
                    sm: "60px",
                    md: "unset",
                    lg: "unset",
                  },
                }}
              >
                {t(
                  "Join us at Warehouse X, the Entertainment Revolution – Live broadcast globally to millions of viewers."
                )}
              </Typography>
            </Box>
            <Box className="flex column" sx={{ gap: 2, marginTop: 3 }}>
              <Typography variant="body1" className="typography">
                {t("Complete the form to start your X journey.")}
              </Typography>
              <Box className="flex column form" sx={{ maxWidth: 392 }}>
                <TextFieldUI
                  title={"Full name"}
                  value={name}
                  onChange={(e) => setname(e.target.value)}
                />
                <TextFieldUI
                  type={"email"}
                  title={"Email"}
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                />
                <TextFieldUI
                  title={"Instagram/OnlyFans"}
                  value={isnta}
                  onChange={(e) => setinsta(e.target.value)}
                />
                <TextFieldUI
                  title={"Country"}
                  value={country}
                  onChange={(e) => setcountry(e.target.value)}
                />
                <TextFieldUI
                  title={"Where did you hear about us"}
                  value={about}
                  onChange={(e) => setabout(e.target.value)}
                />
                <Button
                  onClick={() => setOpen(true)}
                  className="btn btn-primary"
                  sx={{
                    padding: "16px 38px !important",
                    maxWidth: { xs: "100%", sm: 110 },
                  }}
                >
                  {t("Send")}
                </Button>
                {open && (
                  <Box sx={{ p: 2 }}>
                    <Turnstile
                      sitekey="0x4AAAAAAAA06TaTB1VDumSb"
                      theme="dark"
                      onVerify={(token) => handleSend(token)}
                    />
                  </Box>
                )}
              </Box>
              {error && (
                <Alert className="alert" severity="error">
                  {error}
                </Alert>
              )}
            </Box>

            <Box
              className="name"
              sx={{ backgroundImage: "url(" + splash + ")" }}
            >
              <Typography className="typography primary bold xxlg">
                {t("Sabien Demonia")}
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box
        id="about"
        ref={ref1}
        className="background scrollable"
        sx={{
          backgroundImage: "url(" + bg2 + ")",
          backgroundPosition: "right",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Container maxWidth="xl">
          <Box
            className="container flex column content-center"
            sx={{ minHeight: "747px" }}
          >
            <Box
              className={appeared1 ? "appear" : ""}
              sx={{ maxWidth: "1054px", position: "relative" }}
            >
              <TextBackground
                className="bg"
                style={{
                  position: "absolute",
                  zIndex: "1",
                  top: "20px",
                  left: "-40px",
                }}
              />
              <Box sx={{ position: "relative", zIndex: "2" }}>
                <Typography
                  sx={{
                    marginBottom: { xs: "25px", sm: "15px", md: "0", lg: "0" },
                  }}
                  style={{ fontSize: "50px" }}
                  variant="h2"
                  className="typography third"
                >
                  {t("About")}
                  <img alt="X" src={x} className="x_sm" />
                  &nbsp; &nbsp; &nbsp;
                  {t("X Facility")}
                </Typography>
                <Typography
                  variant="body1"
                  className="typography xlg"
                  sx={{ marginBottom: "20px", marginTop: "5px" }}
                >
                  {t(
                    "It’s a live studio platform for you to kick-start or grow your career as a webcam model."
                  )}
                </Typography>
                <Typography variant="body1" className="typography xlg">
                  {t(
                    "Live in a purpose-built webcam studio with professional broadcast facilities and have access to over 30 million new customers monthly on a global scale. Your time in the Warehouse will be broadcast to the world, Reality TV style, and is a great opportunity to grow your fan base."
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box id="tour" ref={ref2} className="background">
        <Box
          className="background-video"
          sx={{
            top: {
              xs: "250px",
              sm: "0",
              md: "0",
              lg: "0",
            },
            video: {
              width: {
                xs: "100%",
                sm: "unset",
                md: "unset",
                lg: "unset",
              },
            },
          }}
        >
          <video autoPlay muted loop id="myVideo">
            <source
              src={"https://media.warehouse-x.io/videos/warehouse-x-3d.mp4"}
              type="video/mp4"
            />
          </video>
        </Box>

        <Container maxWidth="xl">
          <Box
            className="container flex column content-center"
            sx={{
              minHeight: {
                xs: "450px",
                sm: "850px",
                md: "850px",
                lg: "850px",
              },
            }}
          >
            <Box
              className={appeared2 ? "appear" : ""}
              sx={{
                maxWidth: "1054px",
                position: "relative",
                ml: { xs: "50px", sm: "20px", md: "80px", lg: "unset" },
                top: {
                  xs: "-90px",
                  sm: "0",
                  md: "0",
                  lg: "0",
                },
              }}
            >
              <TextBackground
                className="bg"
                style={{
                  position: "absolute",
                  zIndex: "1",
                  top: "-60px",
                  left: "-80px",
                  width: "850px",
                }}
              />
              <Box
                className="flex column"
                sx={{ position: "relative", zIndex: "2" }}
              >
                <Typography
                  sx={{
                    marginBottom: { xs: "25px", sm: "15px", md: "0", lg: "0" },
                  }}
                  variant="h1"
                  className="typography bold primary"
                >
                  {t("Welcome to")}
                </Typography>

                <Box
                  sx={{
                    maxWidth: {
                      xs: "256px",
                      sm: "336px",
                      md: "336px",
                      lg: "336px",
                    },
                    position: "relative",
                    top: "-20px",
                  }}
                >
                  <img alt={t("warehouseX")} src={logo} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box
        className="background sreser"
        style={{
          background:
            "transparent radial-gradient(closest-side at 50% 50%, #E5F33A 0%, #F93079 61%, #8DF33A 85%, #FFFFFF00 100%) 0% 0% no-repeat padding-box",
          backgroundPosition: "130vh 100vh",
        }}
      >
        <Container maxWidth="xl">
          <Box className="container flex column content-center">
            <Box sx={{ maxWidth: "1054px", position: "relative" }}>
              <Box sx={{ mt: 8, mb: 3 }}>
                <Typography
                  variant="h2"
                  className="typography secondary"
                  style={{ fontSize: "50px" }}
                >
                  {t("How does Warehouse X work for you?")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Container>
        <Box className="grid" sx={{ pb: 10 }}>
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 926: 2, 1385: 3, 1850: 4 }}
          >
            <Masonry className="masonry">
              <Box
                ref={box1}
                className={"card" + (render1 ? " fadeInBottom" : "")}
                sx={{
                  background:
                    "transparent linear-gradient(224deg, #77F73B 0%, #ED2779 100%) 0% 0% no-repeat padding-box",
                }}
              >
                <img src={diamond} alt="diamond" />
                <Typography variant="h3" className="typography bold">
                  {t("What we provide")}
                </Typography>
                <Typography variant="bod1" className="typography">
                  {t(
                    "During your stay at X, you will be provided with all food, drinks, on set accommodation and transport. You will also have access to beauty treatments and routines with onsite tanning beds and nail and hair technicians available as well as access to fitness facilities including a 25-metre lap pool."
                  )}
                </Typography>
              </Box>
              <Box
                ref={box2}
                className={"card" + (render2 ? " fadeInBottom" : "")}
                sx={{
                  backgroundImage: "url(" + banner2 + ")",
                  boxShadow: "inset -102px 100px 1000px -110px #77f73b",
                }}
              >
                <People />
                <Typography variant="h3" className="typography bold">
                  {t("Who is watching")}
                </Typography>
                <Typography variant="bod1" className="typography">
                  {t(
                    "Warehouse X will guarantee a minimum of 17 million unique visitors every month (publicly verifiable) with a target of 30 million +, all of which are potential customers and potential new fans or followers"
                  )}
                </Typography>
              </Box>
              <Box
                ref={box3}
                className={"card long" + (render3 ? " fadeInBottom" : "")}
                sx={{
                  backgroundImage: "url(" + banner1 + ")",
                  boxShadow:
                    "inset 0 -250px 1000px #1DA231, inset 0 -350px 1000px black",
                }}
              >
                <Facilities />

                <Box className="flex column" sx={{ gap: "30px", mb: 4 }}>
                  <Typography variant="h3" className="typography bold">
                    {t("X Facilities")}
                  </Typography>
                  <Typography variant="bod1" className="typography">
                    {t(
                      "Warehouse X is a custom-designed film set built in-a-warehouse (really), with a total of over 1600m2 (17 thousand square feet) there is a huge main floor area with gym, Jacuzzi, billiards, ping pong, air hockey, a large cinema, a giant kitchen, a grassed sunbathing area as well as tanning beds, a bar, dance floor and more."
                    )}
                  </Typography>
                  <Typography variant="body1" className="typography">
                    {t(
                      "Also, there are comfortable sleeping quarters for up to 50 models as well as 8 professional broadcast studios for webcamming including fetish rooms and “blank canvas” rooms that can be customized to suit the individual model. We are expanding the space in October to include a large indoor freeform swimming lagoon with sandy beach and tiki bar and a bunch of other party space."
                    )}
                  </Typography>
                </Box>
              </Box>
              <Box
                ref={box4}
                className={"card" + (render4 ? " fadeInBottom" : "")}
                sx={{
                  backgroundImage: "url(" + banner4 + ")",
                }}
              >
                <Otherjob />
                <Typography
                  variant="h3"
                  className="typography bold"
                  sx={{ mb: -1 }}
                >
                  {t("Already got a high paying job?")}
                </Typography>
                <Typography variant="bod1" className="typography">
                  {t(
                    "During your time at X, you are welcome to cam to your regular webcam sites and to continue your regular adult business and online promotional work anywhere including Only Fans and any other cam site or adult site where you are already earning an income from. In fact, being at Warehouse X will be a huge opportunity for you find new fans and followers through our global audience."
                  )}
                </Typography>
              </Box>
              <Box
                ref={box5}
                className={"card" + (render5 ? " fadeInBottom" : "")}
                sx={{
                  backgroundImage: "url(" + banner3 + ")",
                  boxShadow: "inset 150px -80px 1000px black",
                }}
              >
                <Earnings />
                <Typography
                  variant="h3"
                  className="typography bold"
                  sx={{ mb: -1 }}
                >
                  {t("Earnings")}
                </Typography>
                <Typography variant="bod1" className="typography">
                  {t(
                    "Top models at Warehouse-X are earning high 5 figure sums every month.  Models have mutliple ways to earn top dollar including customer tips, direct messaging, private shows, private phone calls, merchadise sales and affiliate offers and more.  We also offer full earnings support 7 days per week."
                  )}
                </Typography>
              </Box>
              <Box
                ref={box6}
                className={"card" + (render6 ? " fadeInBottom" : "")}
                sx={{
                  backgroundImage: "url(" + banner5 + ")",
                  boxShadow:
                    "inset 60px -60px 1000px #ED2779, inset 130px -80px 1000px black",
                }}
              >
                <XPrizes />
                <Typography
                  variant="h3"
                  className="typography bold"
                  sx={{ mb: -1 }}
                >
                  {t("X Prizes")}
                </Typography>
                <Typography variant="bod1" className="typography">
                  {t(
                    "Prizes are on offer to the top earners for each series of Warehouse-X"
                  )}
                </Typography>
              </Box>

              <Box />

              <Box
                ref={box7}
                className={"card" + (render7 ? " fadeInBottom" : "")}
                sx={{
                  backgroundImage: "url(" + banner6 + ")",
                }}
              >
                <Prostaff />
                <Typography
                  variant="h3"
                  className="typography bold"
                  sx={{ mb: -1 }}
                >
                  {t("Professional X staff")}
                </Typography>
                <Typography variant="bod1" className="typography">
                  {t(
                    "Staff at Warehouse X are all seasoned professionals. From the Adult and reality Television industries, we have a strong team of 47 people dedicated to making your experience with us not only awesome but super profitable as well."
                  )}
                </Typography>
              </Box>
            </Masonry>
          </ResponsiveMasonry>
        </Box>

        {/* <Box className="girls">
          <img src={girls} alt="warehouse x girls" />
        </Box> */}
      </Box>
      <Box
        id="qa"
        className="background scrollable"
        sx={{ pt: 10, pb: 10 }}
        style={{
          backgroundImage: "url(" + bg3 + ")",
          backgroundPosition: "right",
        }}
      >
        <Container maxWidth="xl">
          <Box
            className="container flex column content-center"
            sx={{
              minHeight: "747px",
              justifyContent: "space-around !important",
              gap: "150px",
            }}
          >
            <Box className="flex column">
              <Typography
                variant="h2"
                sx={{ fontSize: "45px !important", mb: 3 }}
                className="typography bold third"
              >
                {t("Questions & Answers")}
              </Typography>
              <Box
                className="flex accordions"
                sx={{ gap: "20px 24px", flexWrap: "wrap" }}
              >
                <Accordion disableGutters className="accord">
                  <AccordionSummary
                    expandIcon={<Plus />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="body1" className="typography">
                      {t("Who else will be there?")}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography ariant="body1" className="typography">
                      {t(
                        "Initially there will be non-male models only allowed to live at Warehouse X. There are future plans for a couple’s version of Warehouse X but for now its for the exclusive of the non-male version of human"
                      )}
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion disableGutters className="accord">
                  <AccordionSummary
                    expandIcon={<Plus />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography variant="body1" className="typography">
                      {t("Will I be safe?")}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography ariant="body1" className="typography">
                      {t(
                        "The Warehouse studio is staffed 24/7 by managers, supervisors and security staff, safety is our top priority and you will not only feel at home, but will be safer, during your time as a house mate at Warehouse X"
                      )}
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion disableGutters className="accord">
                  <AccordionSummary
                    expandIcon={<Plus />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                  >
                    <Typography variant="body1" className="typography">
                      {t("Where is Warehouse X located?")}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography ariant="body1" className="typography">
                      {t(
                        "The studio itself is located in Tallinn, Estonia. Tallinn is a very vibrant and wealthy city in the European Union and has been dubbed, “The Silicone Valley of Europe”. Tallinn has a heritage listed old town and is located seaside with many award wining cafe’s and restaurants."
                      )}
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion disableGutters className="accord">
                  <AccordionSummary
                    expandIcon={<Plus />}
                    aria-controls="panel4a-content"
                    id="panel4a-header"
                  >
                    <Typography variant="body1" className="typography">
                      {t("How will I get there?")}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography ariant="body1" className="typography">
                      {t(
                        "We provide flights from the nearest major city to you and can help with travel from other locations. Sometimes we will recoup the flights costs from your earnings once you earn over a certain amount (a lot of $) and this is usually assessed on a case-by-case basis."
                      )}
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion disableGutters className="accord">
                  <AccordionSummary
                    expandIcon={<Plus />}
                    aria-controls="panel5a-content"
                    id="panel5a-header"
                  >
                    <Typography variant="body1" className="typography">
                      {t("Can I leave the Warehouse?")}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography ariant="body1" className="typography">
                      {t(
                        "Of course you can leave the warehouse!! There is a major shopping mall nearby as well as the beautiful beaches that Tallinn has in the summer time as well as tonnes of restaurants and sites to see in the beautiful city of Tallinn."
                      )}
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion disableGutters className="accord">
                  <AccordionSummary
                    expandIcon={<Plus />}
                    aria-controls="panel6a-content"
                    id="panel6a-header"
                  >
                    <Typography variant="body1" className="typography">
                      {t("Can I promote my profile elsewhere while at X?")}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography ariant="body1" className="typography">
                      {t(
                        "Yes, you are welcome to promote other sites that bring you income including Only Fans, we also encourage you to cam to your regular webcam sites from our studios while you are staying with us."
                      )}
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion disableGutters className="accord">
                  <AccordionSummary
                    expandIcon={<Plus />}
                    aria-controls="panel7a-content"
                    id="panel7a-header"
                  >
                    <Typography variant="body1" className="typography">
                      {t("Content Protection")}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography ariant="body1" className="typography">
                      {t(
                        "Any content created at Warehouse X is protected by DMCA takedown service, so if anyone should try to repost your content on another site like a tube site for example, we will work with DMCA to have it removed."
                      )}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Box>
            <Box
              className="banner"
              sx={{ backgroundImage: "url(" + bgX + ")" }}
            >
              <Box className="flex column" sx={{ gap: "30px" }}>
                <Link to={"/#about"} onClick={() => handleScroll("about")}>
                  <Typography variant="body1" className="typography">
                    {t("About Warehouse X")}
                  </Typography>
                </Link>
                <Link to={"/#qa"} onClick={() => handleScroll("qa")}>
                  <Typography variant="body1" className="typography">
                    {t("FAQ")}
                  </Typography>
                </Link>
                <Box className="flex column">
                  <Typography
                      variant="body1" className="typography"
                  >
                    {t("Partners")}
                  </Typography>
                  <Stack
                      direction="row"
                      spacing={1}
                      sx={{
                        alignItems: "center",
                        justifyContent: { xs: "start", md: "start" },
                        flexWrap: "wrap",
                        padding:'10px 0px'
                      }}
                      className="SocialBlock"
                  >
                    <a target="_blank" href={"https://www.svakom.com/"}>
                      <img
                          src={SvakomIcon}
                          alt="Svakom Image"
                          width={120}
                          style={{
                            marginRight: "10px"
                          }}
                      />
                    </a>
                  </Stack>
                </Box>

              </Box>
              <Box
                sx={{
                  display: { xs: "none", sm: "none", md: "block", lg: "block" },
                }}
              >
                <Link to={"/"} onClick={() => handleScroll("head")}>
                  <img src={logo} alt="logo" />
                </Link>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
}

function useIsInViewport(ref) {
  const [isIntersecting, setIsIntersecting] = React.useState(false);

  const observer = React.useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting)
      ),
    []
  );

  React.useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}

export default Main;
