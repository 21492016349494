import React from 'react'
import { useSelector } from 'react-redux'

export default function Modals() {

  const modal = useSelector(state => state.modal);

  return (
    <>
        {modal?.modal?.name === 'post' && 'test'}
    </>
  )
}
